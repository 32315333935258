import React from 'react';
import './DrawerToggleButton.css';

const DrawerToggleButton = props => {
  const classes = `toggle-button-line ${props.classes}`;
  return (
    <button className="toggle-button" onClick={props.click}>
      <div className={classes}></div>
      <div className={classes}></div>
      <div className={classes}></div>
    </button>
  );
};

export default DrawerToggleButton;
