import React, { Component } from 'react';
import Gallery from 'react-grid-gallery';
import './Media.css';
import ImgList from './ImgList/ImgList';

// 2019

// Metal Vest
// 2019 e de fapt 2022
import img2019_1_1 from './../assets/Media/2019/Erasmus/thumbnail.jpeg';
import img2019_1_2 from './../assets/Media/2019/Erasmus/1.jpeg';
import img2019_1_3 from './../assets/Media/2019/Erasmus/2.jpeg';
import img2019_1_4 from './../assets/Media/2019/Erasmus/3.jpeg';
import img2019_1_5 from './../assets/Media/2019/Erasmus/4.jpeg';
import img2019_1_6 from './../assets/Media/2019/Erasmus/5.jpeg';
import img2019_1_7 from './../assets/Media/2019/Erasmus/6.jpeg';

// ARQ Gala
import img2019_2_1 from './../assets/Media/2019/AEG/thumbnail.jpeg';
import img2019_2_2 from './../assets/Media/2019/AEG/1.jpeg';


// Sankt Petersburg Regional Qualifier
import img2019_3_1 from './../assets/Media/2019/World/thumbnail.jpeg';
import img2019_3_2 from './../assets/Media/2019/World/1.jpeg';
import img2019_3_3 from './../assets/Media/2019/World/2.jpeg';
import img2019_3_4 from './../assets/Media/2019/World/3.jpeg';
import img2019_3_5 from './../assets/Media/2019/World/4.jpeg';
import img2019_3_6 from './../assets/Media/2019/World/5.jpeg';
import img2019_3_7 from './../assets/Media/2019/World/6.jpeg';
import img2019_3_8 from './../assets/Media/2019/World/7.jpeg';
import img2019_3_9 from './../assets/Media/2019/World/8.jpeg';
import img2019_3_10 from './../assets/Media/2019/World/9.jpeg';
import img2019_3_11 from './../assets/Media/2019/World/10.jpeg';
import img2019_3_12 from './../assets/Media/2019/World/11.jpeg';
import img2019_3_13 from './../assets/Media/2019/World/12.jpeg';
import img2019_3_14 from './../assets/Media/2019/World/13.jpeg';

// 2020

// Winter Robotics Games
import img2020_1_1 from './../assets/Media/2020/WinterRoboticsGames/thumbnail.jpg';
import img2020_1_2 from './../assets/Media/2020/WinterRoboticsGames/1.jpg';

// Hunedoara Scrimmage
import img2020_2_1 from './../assets/Media/2020/HunedoaraScrimmage/thumbnail.jpg';
import img2020_2_2 from './../assets/Media/2020/HunedoaraScrimmage/1.jpg';

// Alt FM Radio Interview
import img2020_3_1 from './../assets/Media/2020/AltFmRadioInterview/thumbnail.jpg';
import img2020_3_2 from './../assets/Media/2020/AltFmRadioInterview/1.jpg';
import img2020_3_3 from './../assets/Media/2020/AltFmRadioInterview/2.jpg';

// Timisoara Regional Qualifier
import img2020_4_1 from './../assets/Media/2020/TimisoaraRegionalQualifier/thumbnail.jpg';
import img2020_4_2 from './../assets/Media/2020/TimisoaraRegionalQualifier/1.jpg';
import img2020_4_3 from './../assets/Media/2020/TimisoaraRegionalQualifier/2.jpg';
import img2020_4_4 from './../assets/Media/2020/TimisoaraRegionalQualifier/3.jpg';

// Flight Festival
import img2020_5_1 from './../assets/Media/2020/FlightFestival/thumbnail.jpg';
import img2020_5_2 from './../assets/Media/2020/FlightFestival/1.jpg';
import img2020_5_3 from './../assets/Media/2020/FlightFestival/2.jpg';
import img2020_5_4 from './../assets/Media/2020/FlightFestival/3.jpg';
import img2020_5_5 from './../assets/Media/2020/FlightFestival/4.jpg';

import img2021_1_1 from './../assets/Media/2021/NationalChampionship/thumbnail.jpg';
import img2021_1_2 from './../assets/Media/2021/NationalChampionship/1.jpg';
import img2021_1_3 from './../assets/Media/2021/NationalChampionship/2.jpg';
import img2021_1_4 from './../assets/Media/2021/NationalChampionship/3.jpg';
import img2021_1_5 from './../assets/Media/2021/NationalChampionship/4.jpg';
import img2021_1_6 from './../assets/Media/2021/NationalChampionship/5.jpg';
import img2021_1_7 from './../assets/Media/2021/NationalChampionship/6.jpg';
import img2021_1_8 from './../assets/Media/2021/NationalChampionship/7.jpg';

import img2021_2_1 from './../assets/Media/2021/MarylandTechInvitational/thumbnail.jpg';
import img2021_2_2 from './../assets/Media/2021/MarylandTechInvitational/1.jpg';
import img2021_2_3 from './../assets/Media/2021/MarylandTechInvitational/2.jpg';
import img2021_2_4 from './../assets/Media/2021/MarylandTechInvitational/3.jpg';
import img2021_2_5 from './../assets/Media/2021/MarylandTechInvitational/4.jpg';
import img2021_2_6 from './../assets/Media/2021/MarylandTechInvitational/5.jpg';
import img2021_2_7 from './../assets/Media/2021/MarylandTechInvitational/6.jpg';
import img2021_2_8 from './../assets/Media/2021/MarylandTechInvitational/7.jpg';
import img2021_2_9 from './../assets/Media/2021/MarylandTechInvitational/8.jpg';

class Media extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEvent: false,
      eventIndex: -1
    };

    this.showEventImages = this.showEventImages.bind(this);
  }

  showEventImages(index) {
    this.setState({ showEvent: true, eventIndex: index });
  }

  render() {
    let gallery;
    const years = ['2022', '2020', '2021'];
    const thumbnails2019 = [img2019_1_1, img2019_2_1, img2019_3_1];
    const thumbnails2020 = [img2020_1_1, img2020_2_1, img2020_3_1, img2020_4_1, img2020_5_1];
    const thumbnails2021 = [img2021_1_1, img2021_2_1];

    const gridImages2019_1 = [img2019_1_1, img2019_1_2, img2019_1_3, img2019_1_4, img2019_1_5, img2019_1_6, img2019_1_7];
    const gridImages2019_2 = [img2019_2_1, img2019_2_2];
    const gridImages2019_3 = [
      img2019_3_1,
      img2019_3_2,
      img2019_3_3,
      img2019_3_4,
      img2019_3_5,
      img2019_3_6,
      img2019_3_7,
      img2019_3_8,
      img2019_3_9,
      img2019_3_10,
      img2019_3_11,
      img2019_3_12,
      img2019_3_13,
      img2019_3_14
    ];

    const gridImages2020_1 = [img2020_1_1, img2020_1_2];
    const gridImages2020_2 = [img2020_2_1, img2020_2_2];
    const gridImages2020_3 = [img2020_3_1, img2020_3_2, img2020_3_3];
    const gridImages2020_4 = [img2020_4_1, img2020_4_2, img2020_4_3, img2020_4_4];
    const gridImages2020_5 = [img2020_5_1, img2020_5_2, img2020_5_3, img2020_5_4, img2020_5_5];

    const gridImages2021_1 = [
      img2021_1_1,
      img2021_1_2,
      img2021_1_3,
      img2021_1_4,
      img2021_1_5,
      img2021_1_6,
      img2021_1_7,
      img2021_1_8
    ];

    const gridImages2021_2 = [
      img2021_2_1,
      img2021_2_2,
      img2021_2_3,
      img2021_2_4,
      img2021_2_5,
      img2021_2_6,
      img2021_2_7,
      img2021_2_8,
      img2021_2_9
    ];

    const titles2019 = ['Erasmus', 'Arad Elites Gala', 'World Championship'];

    const titles2020 = [
      'Winter Robotics Games',
      'Hunedoara Scrimmage',
      'Alt FM Radio Interview',
      'Timisoara Regional Qualifier',
      'Flight Festival'
    ];

    const titles2021 = ['Bucharest National Championship', 'Maryland Tech Invitational'];

    let gridImages = [];

    // if (this.state.event)
    console.log(this.state.eventIndex);

    if (this.state.eventIndex === 0) {
      gridImages = gridImages2019_1;
    } else if (this.state.eventIndex === 1) {
      gridImages = gridImages2019_2;
    } else if (this.state.eventIndex === 2) {
      gridImages = gridImages2019_3;
    } else if (this.state.eventIndex === 3) {
      gridImages = gridImages2020_1;
    } else if (this.state.eventIndex === 4) {
      gridImages = gridImages2020_2;
    } else if (this.state.eventIndex === 5) {
      gridImages = gridImages2020_3;
    } else if (this.state.eventIndex === 6) {
      gridImages = gridImages2020_4;
    } else if (this.state.eventIndex === 7) {
      gridImages = gridImages2020_5;
    } else if (this.state.eventIndex === 8) {
      gridImages = gridImages2021_1;
    } else if (this.state.eventIndex === 9) {
      gridImages = gridImages2021_2;
    }

    gridImages = gridImages.map(image => {
      let obj = {
        src: image,
        thumbnail: image
      };

      return obj;
    });

    let content;
    if (this.state.showEvent) {
      let title, lastDigitInTitle;
      if (this.state.eventIndex < 3) {
        title = titles2019[this.state.eventIndex];
        lastDigitInTitle = titles2019[title.length - 1];
      }

      if (this.state.eventIndex >= 3 && this.state.eventIndex < 8) {
        title = titles2020[this.state.eventIndex - 3];
        lastDigitInTitle = titles2020[title.length - 1];
      }

      if (this.state.eventIndex >= 8) {
        title = titles2021[this.state.eventIndex - 8];
        lastDigitInTitle = titles2021[title.length - 1];
      }

      content = (
        <div>
          <div className="page-title title-left title-left-event">
            <h2>
              <span>{title}</span>
              {lastDigitInTitle}
            </h2>
          </div>
          <Gallery images={gridImages} enableImageSelection={false} />
        </div>
      );
    } else {
      content = (
        <div>
          <ImgList
            year={years[0]}
            images={thumbnails2019}
            titles={titles2019}
            event={this.showEventImages}
            eventsBeforeThisYear={0}
          />
          {/* <br />
          <br />
          <ImgList
            year={years[1]}
            images={thumbnails2020}
            titles={titles2020}
            event={this.showEventImages}
            eventsBeforeThisYear={3}
          />
          <br />
          <br />
          <ImgList
            year={years[2]}
            images={thumbnails2021}
            titles={titles2021}
            event={this.showEventImages}
            eventsBeforeThisYear={8}
          /> */}
        </div>
      );
    }

    return (
      <div className="media-page">
        <div className="page-title main-title">
          <h2>
            <span>Media</span>
          </h2>
        </div>

        <div className="lists-container">{content}</div>
      </div>
    );
  }
}
export default Media;
