import React from 'react';
import './SideDrawer.css';
import Links from './../Navbar/Elements';
import logo from './../../../../assets/logo.png';

const SideDrawer = props => {
  let drawerClasses = 'side-drawer';

  if (props.show) {
    drawerClasses = 'side-drawer open';
  }

  return (
    <nav className={drawerClasses}>
      <ul>
        <img src={logo} className="side-drawer-logo" alt="Key Pro" />
        {Links}
      </ul>
    </nav>
  );
};
export default SideDrawer;
