import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import img1 from './../../../../assets/Home/Slider/1.jpeg';
import img2 from './../../../../assets/Home/Slider/2.jpeg';
import img3 from './../../../../assets/Home/Slider/3.jpeg';
import img4 from './../../../../assets/Home/Slider/4.jpeg';
import img5 from './../../../../assets/Home/Slider/5.jpeg';
import img6 from './../../../../assets/Home/Slider/6.jpeg';
import img7 from './../../../../assets/Home/Slider/7.jpeg';

const BannerSlider = () => {
  return (
    <Carousel
      className="slider"
      emulateTouch
      showStatus={false}
      showThumbs={false}
      autoPlay
      interval={2000}
      infiniteLoop
      useKeyboardArrows
    >
      <div>
        <img src={img1} alt="" />
      </div>
      <div>
        <img src={img2} alt="" />
      </div>
      <div>
        <img src={img3} alt="" />
      </div>
      <div>
        <img src={img4} alt="" />
      </div>
      <div>
        <img src={img5} alt="" />
      </div>
      <div>
        <img src={img6} alt="" />
      </div>
      <div>
        <img src={img7} alt="" />
      </div>
    </Carousel>
  );
};

export default BannerSlider;
