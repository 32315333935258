import React, { Component } from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebook } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="container">
          <div className="left section">
            <div className="footer-title">
              <h3>Navigation</h3>
            </div>

            <div className="footer-links footer-links-left">
              <a href="/home">Home</a>
              <a href="/news">News</a>
              <a href="/media">Media</a>
              <a href="/about">About</a>
            </div>
          </div>
          <div className="middle section">
            <div className="footer-title">
              <h3>Social</h3>
            </div>

            <div className="footer-links footer-links-middle">
              <a
                href="https://www.facebook.com/DeltaForceFTC"
                className="facebook-icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} size="3x" style={{ color: '#3b5998' }} className="icon" />
              </a>

              <a
                href="https://www.instagram.com/delta_force_robotics/"
                className="facebook-icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} size="3x" style={{ color: '#C13584' }} className="icon" />
              </a>
            </div>
          </div>
          <div className="right section">
            <div className="footer-title">
              <h3>Sponsors</h3>
            </div>
            <div className="footer-links footer-links-right">
              <a href="/sponsors">Our sponsors</a>
              <a href="/sponsor">Sponsor us</a>
            </div>
          </div>
        </div>
        <div className="copyright">
          Copyright &copy; 2020-2022 Delta Force Robotics. All rights reserved. Website designed and developed by{' '}
          <a href="https://github.com/robertfedus">Robert Feduș</a>
        </div>
      </footer>
    );
  }
}

export default Footer;
