import React from 'react';
import './Team.css';

const Team = () => {
  return (
    <div className="team-section">
      <h2 className="about-title">Team</h2>
      <p style={{ lineHeight: '1.7', textAlign: 'justify' }}>
        Delta Force is a robotics team composed of passionate young people, and at
        the same time very talented. A team that motivates itself from the gains achieved, but also from the
        defeats. Under the careful guidance of the coordinating teacher Florin Feher who helps us in solving
        the problems that arise in the process of building the robot, we try to get better and better, even if
        this activity takes away about all the free time left after class.
      </p>
    </div>
  );
};

export default Team;
