import React from 'react';
import engIcon from './../assets/Sponsor/eng.png';
import roIcon from './../assets/Sponsor/ro.png';
import './Sponsor.css';

const Romanian = (props) => {
  return (
    <div className="english">
      <div className="page-title">
        <h2>
          <span>Sponsorizați - ne</span>
        </h2>
      </div>
      <div className="flags-wrapper">
        <div className="lang-icons">
          <img src={roIcon} alt="Romanian" />
          <img
            src={engIcon}
            alt="English"
            className="switch"
            onClick={() => props.changeLang('eng')}
          />
        </div>
      </div>

      <div className="container">
        <div className="page-title left">
          <h2>
            <span>Investiți în viitor</span>
          </h2>
        </div>

        <p>
        Această  sponsorizare va fi o investiție în viitor. Credem că este responsabilitatea noastră să promovăm educația STEM, să formăm noi lideri și să formăm o nație prin educație. Drept mulțumire, vom afișa logo-ul companiei pe robot și pe toate celelalte materiale ale echipei. 
<br/>
<br/>
Dacă doriți să susțineți echipa de robotică Delta Force, actuala campioană mondială, puteți să o faceți în mod GRATUIT prin redirecționarea a 3,5% din impozitul pe venit. <a href="https://formular230.ro/asociatia-delta-inovations" target="_blank">Apăsați aici</a>, completați câmpurile cu datele dumneavoastră și trimiteți-ne formularul generat pe adresa de email: deltaforcerobotics@gmail.com sau prin mesaj direct pe pagina noastră de Facebook iar mai departe, ne vom ocupa noi de depunerea lui la ANAF. 
Dacă sunt persoane juridice care doresc să ne sprijine, pot accesa <a target="_blank" href="https://deltaforce.ro/sponsorship.pdf">prezentarea aceasta</a>. 
Mulțumim pentru susținere!
        </p>
      </div>

      {/* <div className="cards">
        <div className="card">
          <div className="page-title left">
            <h2>
              <span>Sponsor Orange</span>
            </h2>
          </div>
          <p>
            Peste 5000 USD în bani, bunuri sau servicii.
            <br />
            Beneficii:
            <br />
            <br />
            <span>&#8212;</span> Puteți solicita ca echipa noastra, Delta Force,
            să apară la evenimentele companiei dvs
            <br />
            <span>&#8212;</span> Logoul dvs. va fi plasat în cel mai proeminent
            loc al robotului nostru și pe alte materiale promoționale, cum ar fi
            bannerele
            <br />
            <span>&#8212;</span> Menționarea afacerii dumneavoastră la
            competiții
            <br />
            <span>&#8212;</span> Toate avantajele unui sponsor gold sau silver
          </p>
        </div>

        <div className="card">
          <div className="page-title left">
            <h2>
              <span>Sponsor Gold</span>
            </h2>
          </div>
          <p>
            Până la 5000 USD în bani, bunuri sau servicii.
            <br />
            Beneficii:
            <br />
            <br />
            <span>&#8212;</span> Logoul dvs. va fi plasat pe toate materialele
            promoționale
            <br />
            <span>&#8212;</span> Toate beneficiile unui sponsor silver.
          </p>
        </div>

        <div className="card">
          <div className="page-title left">
            <h2>
              <span>Sponsor Silver</span>
            </h2>
          </div>
          <p>
            Până la 1000 USD în bani, bunuri sau servicii.
            <br />
            Benefits:
            <br />
            <br />
            <span>&#8212;</span> Logoul companiei dvs. va fi plasat pe robot
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default Romanian;
