import React from 'react';

const links = ['Home', 'News', 'Media', 'About', 'Sponsors', 'Sponsor Us'];

const Links = links.map((link) => {
  const { pathname } = window.location;
  const path = pathname;

  let active;
  switch (path) {
    case '/':
      active = 'home';
      break;
    case '/home':
      active = 'home';
      break;
    case '/ftc':
      active = 'ftc';
      break;
    case '/media':
      active = 'media';
      break;
    case '/about':
      active = 'about';
      break;
    case '/news':
      active = 'news';
      break;
    case '/sponsors':
      active = 'sponsors';
      break;
    case '/sponsor':
      active = 'sponsor us';
      break;
    default:
      active = 'home';
      break;
  }

  let to = '/' + link.toLowerCase();

  if (link === 'Sponsor Us')
    to = '/sponsor';

  return (
    <li className={active === link.toLowerCase() ? 'active' : 'a'} key={link}>
      <a href={to}>{link}</a>
    </li>
  );
});

export default Links;
