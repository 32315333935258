import React, { Component } from 'react';
import English from './English';
import Romanian from './Romanian';

class Sponsor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: 'eng'
    };

    this.changeLang = this.changeLang.bind(this);
  }

  changeLang(lang) {
    this.setState({ lang });
  }

  render() {
    let lang;
    if (this.state.lang === 'eng')
      lang = <English changeLang={this.changeLang} />;
    else lang = <Romanian changeLang={this.changeLang} />;

    return <div className="sponsor-page">{lang}</div>;
  }
}

export default Sponsor;
