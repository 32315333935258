import React from 'react';
import './Outreach.css';
import img1 from './../../../assets/About/Outreach/1.jpg';
import img2 from './../../../assets/About/Outreach/2.jpg';
import firstImg from './../../../assets/About/Outreach/first.jpeg';

const Outreach = () => {
  return (
    <div className="outreach-section">
      <h2 className="about-title">About FIRST</h2>
      <div className="container">
        <div className="img-container">
          {/* <div className="img-1">
            <img src={img1} alt="Delta Force Robotica" />
          </div>
          <div className="img-2">
            <img src={img2} alt="Delta Innovations" />
          </div> */}
          <div style={{margin: '0 auto'}}>
            <img src={firstImg} alt="Delta Force Robotics" />
          </div>
        </div>
        <p style={{ lineHeight: '1.7', textAlign: 'justify' }}>
        The mission of FIRST is to inspire young people to be science and technology leaders and innovators, by engaging them in exciting mentor-based programs that build science, engineering, and technology skills, that inspire innovation, and that foster well-rounded life capabilities including self-confidence, communication, and leadership.
          <br/>
          <br/>
        The FIRST Core Values emphasize friendly sportsmanship, respect for the contributions of others, teamwork, learning, and community involvement and are part of our commitment to fostering, cultivating, and preserving a culture of equity, diversity, and inclusion. The FIRST Community expresses the FIRST philosophies of Gracious Professionalism  and Coopertition through the Core Values:
        <br/>
        * Discovery: We explore new skills and ideas.
        <br/>
        * Innovation: We use creativity and persistence to solve problems.
        <br/>
        * Impact:  We apply what we learn to improve our world.
        <br/>
        * Inclusion: We respect each other and embrace our differences.
        <br/>
        * Teamwork: We are stronger when we work together.
        <br/>
        * Fun: We enjoy and celebrate what we do!
        </p>
      </div>
    </div>
  );
};

export default Outreach;
