import React, { Component } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  // withRouter
} from 'react-router-dom';
// import { createStore } from 'redux';

import Toolbar from './General/components/Navbar/Toolbar/Toolbar';
import Footer from './General/components/Footer/Footer';
import Main from './General/components/Main/Main';
import Home from './Home/Home';
import Season from './Season/Season';
import Media from './Media/Media';
import About from './About/About';
import News from './News/News';
import Sponsor from './Sponsor/Sponsor';
import Sponsors from './Sponsors/Sponsors';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Toolbar />
          <Main>
            <Switch>
              <Route path="/" component={Home} exact />
              <Route path="/home" component={Home} exact />\
              <Route path="/news" component={News} exact />\
              <Route path="/season/2022" component={Season} exact />
              <Route path="/media" component={Media} exact />
              <Route path="/about" component={About} exact />
              <Route path="/sponsor" component={Sponsor} exact />
              <Route path="/sponsors" component={Sponsors} exact />
            </Switch>
          </Main>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
