import React from 'react';
import './Post.css';

const Post = (props) => {
  return (
    <div className="post">
      <div className="post-title-container">
        <h2>{props.title}</h2>
      </div>
      <span className="post-date">{props.date}</span>
      <div className="post-image-container">
        <img src={props.image} alt="Delta Force FTC" />
      </div>
      <div className="post-text">
        <p>{props.text}</p>
      </div>
    </div>
  );
};

export default Post;
