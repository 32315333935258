import React, { Component } from 'react';
import './ImgList.css';
import Image from './Image';

class ImgList extends Component {
  constructor(props) {
    super(props);
    this.imageClicked = this.imageClicked.bind(this);
  }

  imageClicked(index) {
    this.props.event(index);
  }

  render() {
    const images = this.props.images.map((img, index) => (
      <Image
        img={img}
        title={this.props.titles[index]}
        index={index + this.props.eventsBeforeThisYear}
        clicked={this.imageClicked}
      />
    ));
    let year = this.props.year;
    const lastDigitInYear = year[year.length - 1];
    year = year.slice(0, -1);

    return (
      <div className="gallery-section">
        <div className="page-title title-left">
          <h2>
            <span>{year}</span>
            {lastDigitInYear}
          </h2>
        </div>
        {images}
      </div>
    );
  }
}

export default ImgList;
