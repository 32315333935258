import React, { Component } from 'react';
import './Members.css';
import taviImg from './images/tavi.jpg';
import fedusImg from './images/fedus.jpg';
import arminImg from './images/armin.jpg';
import danutImg from './images/danut.jpg';
import grasuImg from './images/grasu.jpg';
import aidaImg from './images/aida.jpg';
import paulImg from './images/paul.jpg';
import raulImg from './images/raul.jpg';
import adamImg from './images/adam.jpg';
import vivianaImg from './images/viviana.jpg';
import group from './images/group.jpeg';

import Member from './Member/Member';

class Members extends Component {
  render() {
    return (
      <div className="members-section">
        <h2 className="about-title">Members</h2>
        <div style={{maxWidth: '70vw', padding: '0em', margin: '0 auto'}}>
          <img src={group} alt="delta force robotics" style={{width: '100%'}}  />
        </div>
        {/* <div>
          <Member
            image={taviImg}
            name="Octavian Botoșan"
            role="Team Captain"
            age="17"
            grade="11"
            description="
            Hi, my name is Tavi, I'm 17 years old and I'm the captain of the Delta Force team. Within the team I deal with administrative tasks, but also with the design and programming of the robot.
            I am passionate about robotics and Computer Science. After finishing high school, I want to remain a mentor within the team and help develop Robotics in Romania. As a future job I would like something in the IT field, for example Software Engineer or maybe even a robot designer in bigger projects. */}
            {/* "
          />
          <Member
            image={fedusImg}
            name="Robert Feduș"
            role="Programmer"
            age="17"
            grade="11"
            description="
            My name is Robert Feduș and I am the programmer for the Delta Force FTC team. I find myself extremely lucky to be able to take part in this team and fight for victory, especially since this is the kind of engineering, I find hope to be practicing in the future.
            I am 17 years old and enjoy spending my free time reading, listening to music, programming, playing the guitar and contemplating about my future. A future of technology, robotics, software and engineering. Besides, this isn’t MY future. Tech is the future for everyone, and FTC is a good exercise into that direction.
            "
          />
          <Member
            image={arminImg}
            name="Armin Munteanu"
            role="Technician"
            age="17"
            grade="11"
            description="
            Hey, I'm Armin Raul Munteanu, I'm 17 years old and I am in the technic division.
            I'm in the 11th grade and I am especially mad about Robotics, but I also love cars and basketball. Generally, I am a calm, loyal, friendly, responsible and an open-minded person.
            Most of the time I am an open person to new things and I like being a persevering person in what I am doing. Also, I love the team because everybody is working together.
            "
          />
          <Member
            image={danutImg}
            name="Dănuț Tol"
            role="Technician"
            age="17"
            grade="11"
            description="
            My name's Dănuț Tol and I am member of the programming division. I am 17 years old and I'm in the 11th grade at the National Highschool of IT. Since childhood, I've always been passionate about anything related to the IT domain.
            From playing video games on PC to Electronics, I have always found something that attracts me. I have always wanted to become a member of Delta Force because I've always wanted to be a part of human evolution and I'm glad I found the way. I also love the “Matrix” trilogy.
            "
          />
          <Member
            image={grasuImg}
            name="Robert Rus"
            role="Technician"
            age="16"
            grade="10"
            description="
            My name is Robert Rus Andrei and I am 16 years old and I study at the National Highschool of IT. I am a calm person and an involved type of guy in anything that I do. I have chosen this high school because I like working in a team and building robots. This passion began ever since my first school project at Physics, where I had to build a mixer.
            Now I have joined this team and I am helping the technic division. I hope that I will be able to help my team as much as I can, while I'm a part of it.
            "
          />
          <Member
            image={aidaImg}
            name="Aida Pero"
            role="Technician"
            age="17"
            grade="11"
            description="
            Hi! My name is Aida Pero and I am a member of the technic division in Delta Force. I am 17 years old and I study at the National Highschool of IT Arad, in the 11th grade. I enjoy spending my spare time taking care of my animals, watching historical documentaries, but also to stay with my friends.
            The First Tech Challenge competition will be a very helpful experience, I hope to know what it really means to work in a team, it will improve my ability to relate to others. The competition will help me in the future because I want to continue in the IT domain.
            "
          />
          <Member
            image={paulImg}
            name="Paul Păcurariu"
            role="Technician"
            age="18"
            grade="12"
            description="
            Hi! My name is Pacurariu Paul and I am a 18-year -old student at the Mathematics and Computer Science profile. I have been passionate about robotics for about 2 years and I have been interested in science experiments since 6th grade. I build my first radio-controlled car in 9th grade and after that I was fortunate to join Delta Force. I am also interested in the science of cooking and mixology. I spend most of my free time either working at the robotics club, cooking, taking photos or gaming. The First Tech Challenge will surely improve my ability to listen and cooperate with my teammates. Moreover, I believe this competition will help enhance my mechanical knowledge.
            "
          />
          <Member
            image={raulImg}
            name="Raul Muntean"
            role="Marketing"
            age="17"
            grade="11"
            description="
            My name is Raul Muntean, I'm 17 years old and I am a student at the National High school of IT Arad. I love computers, Informatics and public speaking. I am an outgoing person and I pretty much adapt to any situation. I love working in a team and helping everybody, I can be a people's pleaser sometimes but that's my way.
            I have joined this team ever since it was founded and I love this competition because it brings the best out of us. I am currently working on the Engineering Notebook and I'm a part of the marketing division.
            "
          />
          <Member
            image={adamImg}
            name="Alex Ioniță"
            role="Marketing"
            age="17"
            grade="11"
            description="
            My name is Alex Ioniță, I am 17 years old and I am in the 11th grade at the National High school of Informatics in Arad. I am a member of the marketing division for team Delta Force. 
            I love social media and Psychology and that is why I am doing my best work in the marketing division. I think that First Tech Challenge will help me in the future achieve several goals that I have in my mind.
            "
          />
          <Member
            image={vivianaImg}
            name="Viviana Cojocaru"
            role="Marketing"
            age="17"
            grade="11"
            description="
            Hello my name is Viviana Cojocaru and I am a member of the Marketing division in Delta Force. I am 17 years old; I am in 11th grade at the National Highschool of IT in Arad. I enjoy spending my free time watching historical movies, listening to music and talking with my friends. 
            The First Tech Challenge competition will improve my capacity to speak in front of a public and ability to work in a team.
            "
          /> */}

      </div>
    );
  }
}

export default Members;
