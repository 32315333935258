import React from 'react';
import './Banner.css';
import logo from './../../../assets/logo.png';
import BannerSlider from './BannerSlider/BannerSlider';

const Banner = () => {
  return (
    <div className="home-banner">
      <div className="left">
        <div className="home-banner-img">
          <img src={logo} alt="Delta Force" />
        </div>
        <div className="title-wrapper">
          <h1 className="home-main-title">
            Delta <br style={{ display: 'none' }} />
            Force
            <br />
            Robotics
            <br />
            <span style={{ color: 'white' }}>FTC #17713</span>
          </h1>
        </div>
      </div>

      <div className="right">
        <BannerSlider />
      </div>
    </div>
  );
};

export default Banner;
