import React, { Component } from 'react';
import Mentor from './Mentor/Mentor';
import feherImg from './images/feher.jpg';
import corinaImg from './images/corina.jpg';
import laurentiuImg from './images/laurentiu.jpg';

class Members extends Component {
  render() {
    return (
      <div className="members-section">
        <h2 className="about-title">Mentors</h2>
        <div>
          <Mentor
            image={corinaImg}
            name="Corina Botoșan"
            role="Head Mentor"
            description="
            I am Corina and I am the leading mentor of the team and also the parent of one of the students. The reason I chose to mentor this team and help the students is because they have a lot of potential in this competition and I think it will help bring more knowledge to people about Robotics and it is also a good form of educating the youth to work in pairs and under stress. 
            My job is to communicate with the events' organizers, to help the students whenever they need a guiding hand and to coordinate the team, with the help of the other mentors.
            "
          />
          <Mentor
            image={feherImg}
            name="Florin Feher"
            role="Technic Mentor"
            description="
            My name is Feher Florin and I am a teacher at the National High School of IT Arad and I am responsible of helping the team with the technical and engineering part, whenever they ask for assistance or I feel I should give them a helping hand. I like working with the team and trying to solve any kind of problems we encounter, together with the students. 
            I hope this season we are going to succeed in everything that we want to and I hope we could win many matches.
            "
          />
          {/* <Mentor
            image={laurentiuImg}
            name="Laurențiu Puf"
            role="Technic Mentor"
            description="
            Hello, I am Laurentiu Puf and I am the parent of one of the students in Delta Force. Before I was asked to be one of the mentors, I observed the children working and I saw how the competition was. I was very pleased and proud when I was asked to be a mentor. I've decided that I would do anything I can to help them fulfil their dreams. My objectives in this team are to assist them in outreach events and sponsorships. 
            I talk with them every day, about the team's Facebook and Instagram pages, about how we should be going to companies and showcase them our competition and the work that was put in, and to help on connecting with people that can help the team.
            "
          /> */}
        </div>
      </div>
    );
  }
}

export default Members;
