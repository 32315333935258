import React from 'react';
import './Robots.css';
import RobotsSlider from './RobotsSlider/RobotsSlider';
import robotIMG from './robot.jpeg';

const Robots = () => {
  return (
    <div className="home-robots">
      <div className="lines-title">
        <h2>
          <span>The Robot We Built</span>
        </h2>
      </div>
      {/* <RobotsSlider /> */}
      <a href="/season/2022"><img src={robotIMG} alt="" style={{maxHeight: '25em'}}/></a>
    </div>
  );
};

export default Robots;
