import React from 'react';
import './Image.css';

const Image = (props) => {
  return (
    <div className="image" onClick={() => props.clicked(props.index)}>
      <img src={props.img} alt="" />
      <div className="title">
        <span>{props.title}</span>
      </div>
    </div>
  );
};

export default Image;
