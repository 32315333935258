import React from 'react';

const Sponsor = (props) => {
  return (
    <div className="member" style={{display: 'block', width: '22em'}}>
        <h4 className="name" style={{textAlign: 'center'}}>{props.name}</h4>
    <br/>
      <img src={props.image} alt="" style={{height: '10em'}} />

      <div className="details">
{/* 
        <div className="detail-wrapper">
          <p style={{ marginTop: '1em' }}>{props.description}</p>
        </div> */}
      </div>
    </div>
  );
};

export default Sponsor;
