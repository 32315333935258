import React from 'react';
import engIcon from './../assets/Sponsor/eng.png';
import roIcon from './../assets/Sponsor/ro.png';
import './Sponsor.css';
// import { propTypes } from 'react-grid-gallery';

const English = (props) => {
  return (
    <div className="english">
      <div className="page-title">
        <h2>
          <span>Sponsor Us</span>
        </h2>
      </div>
      <div className="flags-wrapper">
        <div className="lang-icons">
          <img src={engIcon} alt="English" />
          <img
            src={roIcon}
            alt="Romanian"
            className="switch"
            onClick={() => props.changeLang('ro')}
          />
        </div>
      </div>

      <div className="container">
        <div className="page-title left">
          <h2>
            <span>Invest Into the Future</span>
          </h2>
        </div>

        <p>
        This sponsorship will be an investment into the future. We believe it is our responsibility to promote STEM education, to train new leaders and to form a nation through education. As a thank you, we will display the company’s logo on the robot and all other team materials.
<br/>
<br/>
If you want to support for FREE the Delta Force robotics team, the current world champion, you can do it for free by redirecting 3.5% of your income tax (romanian residents only). <a href="https://formular230.ro/asociatia-delta-inovations" target="_blank">Click here</a>, fill in the fields with your data and send us the generated form to the email address: deltaforcerobotics@gmail.com or by direct message on our Facebook page and further , we will take care of submitting it to ANAF.
If there are companies that want to support us, they can access <a target="_blank" href="https://deltaforce.ro/sponsorship.pdf">this presentation</a>.
Thanks for your support!
        </p>
      </div>

      {/* <div className="cards">
        <div className="card">
          <div className="page-title left">
            <h2>
              <span>Orange Sponsor</span>
            </h2>
          </div>
          <p>
            Over $5000 in money, goods or services.
            <br />
            Benefits:
            <br />
            <br />
            <span>&#8212;</span> You can request our team, Delta Force, and our
            robot to appear at your company's events
            <br />
            <span>&#8212;</span> Your logo will be placed in the most prominent
            place on our robot and on other promotional materials such as
            banners
            <br />
            <span>&#8212;</span> Recognition on behalf of the team announced at
            the competition
            <br />
            <span>&#8212;</span> All the benefits of Gold and silver
          </p>
        </div>

        <div className="card">
          <div className="page-title left">
            <h2>
              <span>Gold Sponsor</span>
            </h2>
          </div>
          <p>
            Up to $5000 in money, goods or services.
            <br />
            Benefits:
            <br />
            <br />
            <span>&#8212;</span> Your logo will be placed on all promotional
            materials
            <br />
            <span>&#8212;</span> All Silver benefits
          </p>
        </div>

        <div className="card">
          <div className="page-title left">
            <h2>
              <span>Silver Sponsor</span>
            </h2>
          </div>
          <p>
            Up to $1000 in money, goods or services.
            <br />
            Benefits:
            <br />
            <br />
            <span>&#8212;</span> Logo on the robot
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default English;
