import React, { Component } from 'react';
import './About.css';
import Menu from './components/Menu/Menu';
import Team from './components/Team/Team';
import Members from './components/Members/Members';
import Mentors from './components/Mentors/Mentors';
import Website from './components/Website/Website';
import Outreach from './components/Outreach/Outreach';

class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      section: 'team'
    };
  }

  clickedItem(index) {
    if (index === 0) this.setState({ section: 'team' });
    if (index === 1) this.setState({ section: 'members' });
    if (index === 2) this.setState({ section: 'mentors' });
    // if (index === 3) this.setState({ section: 'website' });
    if (index === 3) this.setState({ section: 'outreach' });
  }

  render() {
    let underlinedItem, content;
    if (this.state.section === 'team') {
      underlinedItem = 0;
      content = <Team />;
    }
    if (this.state.section === 'members') {
      underlinedItem = 1;
      content = <Members />;
    }
    if (this.state.section === 'mentors') {
      underlinedItem = 2;
      content = <Mentors />;
    }
    // if (this.state.section === 'website') {
    //   underlinedItem = 3;
    //   content = <Website />;
    // }
    if (this.state.section === 'outreach') {
      underlinedItem = 3;
      content = <Outreach />;
    }

    return (
      <div className="about-page">
        <div className="menu-section">
          <Menu underlined={underlinedItem} clickedItem={this.clickedItem.bind(this)} />
        </div>
        <div className="container">{content}</div>
      </div>
    );
  }
}

export default About;
