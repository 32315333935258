import React from 'react';
import './Season.css';
import skystone from './../assets/Robot/skystone.jpg';
import robot from './robot.jpeg';
import freight from './freight.png';

const Robot = () => {
  return (
    <div className="robot-page">
      <div className="page-title">
        <h2>
          <span>2021-2022 FTC Season - Freight Frenzy</span>
        </h2>

      </div>

      <div className="page-title">
        <h2>
          <span>Freight Frenzy - The Challenge</span>
        </h2>
      </div>


      <div className="header-text">
        <p>
        FREIGHT FRENZY℠ presented by Raytheon Technologies is played on a 12 ft. x 12 ft. (3.7m x 3.7m) square field with
approximately 1 ft. (0.3 m) high walls and a soft foam mat floor. There are two Alliances – “red” and “blue” made up of two Robots each. Alliances earn points by collecting Freight from the Warehouse and Scoring the Freight in the Storage Unit, their Alliance Shipping Hub, or the Shared Shipping Hub.
See the full video game description <a href="https://youtu.be/I6lX12idAf8" target="_blank">here.</a>
        </p>
      </div>

      <div className="img-video">
        <div className="img-container">
          <img src={freight} alt="" />
        </div>
        <div className="video-container">
          <iframe
            title="YouTube iframe"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/qnAq9K8zn3o"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="page-title">
        <h2>
          <span>The Robot</span>
        </h2>
      </div>

      <div className="overview-robotpic">
        <div className="overview-container" style={{ color: 'white' }}>
          <div className="page-title">
            <h2>
              <span>Robot Abilities</span>
            </h2>
          </div>
          <ul className="lines-list">
            <li>310 points solo (66 autonomous, 148 tele-op, 96 endgame)</li>
            <li>20 freight on Shared Hub in 90 seconds</li>
            <li>5 seconds double cap</li>
            <li>6 freight autonomous</li>
            <li>Carousel deliver + duck place autonomous</li>
          </ul>
        </div>

        <div className="image-container">
          <img src={robot} alt="" />
          <br/>
          <br/>
          <a target="_blank" href="https://myhub.autodesk360.com/ue2cd3a7e/g/shares/SH9285eQTcf875d3c53905adc6c52e721044"><button>View Robot CAD (3D model)</button></a>
        </div>
      </div>

      <div className="competitions">
        <div className="page-title">
          <h2>
            <span>Top Results</span>
          </h2>
        </div>
        <ul className="lines-list">
          <li><b>FIRST Championship</b>
            <br/>
World Champions
<br/>
 Winning Alliance Captain Franklin Division</li>
          <li><b>Romanian National Championship</b>
            <br/>
Inspire Award
<br/>
Judge Award </li>
          <li><b>Timisoara Regional Championship</b>
            <br/>
Design Award
<br/>
Top Ranked Team</li>
          <li>
          <b>Russian Regional Championship</b>
          <br/>
Collins Aerospace Innovate Award Winner
<br/>
Top Ranked Team
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Robot;
