import React, { Component } from 'react';
import logo from './../../../../assets/logo.png';
import './Navbar.css';
import DrawerToggleButton from './../SideDrawer/DrawerToggleButton';
import Links from './Elements';

class Nav extends Component {
  constructor() {
    super();

    this.state = {
      scrolled: false
    };
  }

  // componentDidMount() {
  //   window.addEventListener('scroll', () => {
  //     const isTop = window.scrollY < 30;
  //     if (!isTop) {
  //       this.setState({ scrolled: true });
  //     } else {
  //       this.setState({ scrolled: false });
  //     }
  //   });
  // }

  // componentWillUnmount() {
  //   window.removeEventListener('scroll');
  // }
  shouldComponentUpdate() {
    return true;
  }

  render() {
    // const { scrolled } = this.state;

    return (
      <header className="toolbar">
        <nav>
          <div className="toolbar-toggle-button">
            <DrawerToggleButton click={this.props.drawerClickHandler} />
          </div>
          <div style={{ textAlign: 'center' }}>
            <div className="toolbar-logo">
              <a href="/">
                <img src={logo} alt="Delta Force" />
                <h3>Delta Force</h3>
              </a>
            </div>
          </div>
          <div className="spacer"></div>
          <div className="toolbar-navigation-items">
            <ul>{Links}</ul>
          </div>
        </nav>
      </header>
    );
  }
}

export default Nav;
