import React from 'react';
import './BriefInfo.css';
import lniLogo from './../../../assets/Home/Info/lni.png';

const BriefInfo = () => {
  return (
    <div className="home-info">
      <div className="lines-title">
        <h2>
          <span>Brief Info</span>
        </h2>
      </div>

      <div className="container">
        <div className="left">
          <div className="lni-logo">
            <a href="http://www.lniarad.ro/" target="_blank" rel="noopener noreferrer">
              <img src={lniLogo} alt="Liceul National de Informatica Arad" />
            </a>
          </div>
        </div>
        <div className="right">
          <div className="text">
            <p>
              Delta Force was founded in October 2018 at the initiative of three young people who have been
              joined by robotics. They wanted to have a club to meet and build robots to go to competitions.
            </p>
          </div>
          <div className="button-wrapper">
            <a href="/about">
              <button>Learn more</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BriefInfo;
