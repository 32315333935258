import React, { Component } from 'react';
import axios from 'axios';
import './News.css';
import Post from './Post/Post';
import img1 from './images/1.jpg';
import img2 from './images/2.png';
import img3 from './images/3.jpg';
import img4 from './images/4.jpeg';
import img5 from './images/5.jpeg';
import img6 from './images/6.jpeg';

class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newsLoaded: false,
      news: []
    };

    this.fetchNews = this.fetchNews.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount = () => {
    axios
      .get('https://deltaforce-back.herokuapp.com/news')
      .then(response => {
        // handle success
        console.log(response);
        const news = response.data;
        this.setState({ news, newsLoaded: true });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  fetchNews = () => {};

  render() {
    let content;
    // if (this.state.newsLoaded === false) content = 'Loading news...';
    // else {
    //   content = this.state.news.news.map((el) => {
    //     return (
    //       <Post
    //         title={el.title}
    //         date={el.date}
    //         image={el.image}
    //         text={el.text}
    //       />
    //     );
    //   });
    // }

    return (
      <div className="news-page">
        <div className="page-title main-title">
          <h2>
            <span>News</span>
          </h2>
        </div>
        <Post
        title="We Were Invited to Maryland Tech Invitational"
        date="24-06-2022"
        image={img6}
        text="Thanks to the excellent results on the playing field, we were invited to Maryland Tech Invitational (MTI) 2022. The best teams in the world will participate in this prestigious competition which will take place between 24.06.2022-26.06.2022. We are looking forward to taking part for the second time!"
        />
        <Post
        title="We Won the World Championship"
        date="21-04-2022"
        image={img5}
        text="We wrote history!
        We are extremely happy to have achieved what others thought impossible, even us. 
        After all 8 qualifying matches won, the team became captain of the alliance. Winning the semifinals and finals of the Franklin division, we moved on to the grand final and after some intense matches, we emerged victorious!
        We won the FIRST 2022 Championship in Houston! We are the first international team to have won the championship."
        />
        <Post
        title="We Qualified For the World Championship"
        date="02-04-2022"
        image={img4}
        text="After two intense days of work we qualified for the World Championship in Houston, Texas which will take place during the 20th-23rd of April. Besides this, we won the Inspire Award, the most prestigious award offered throughout this competition, as well as the Judge Award offered by Respo Deee association."
        />
        <Post
          title="We Qualified For the National Championship"
          date="19-06-2021"
          image={img1}
          text="On the occasion of participating in the Regional in Timisoara, our team ranked second in the Control Award, second place in the number of points obtained and so we will participate in the National which is held in Bucharest from 28 to 30 August 2021. We thank the sponsors and teachers who have been with us throughout this period!"
        />
        <Post
          title="We Were Invited to Maryland Tech Invitational"
          date="03-05-2021"
          image={img2}
          text="After winning several national and international competitions in the First Tech Challenge, team Delta Force of the National High School of Informatics will compete in America between July 31 and August 1. Due to the performances obtained in the “Ultimate Goal Challenge” season participate in the most prestigious robotics competition in the USA, Maryland Tech Invitational. 32 teams from around the world will take part in this competition, teams that have demonstrated excellence in science, technology, engineering and mathematics (STEM) for a highly competitive tournament quality."
        />
        <Post
          title="We Won the First Place at the Infinity Bolts Scrimmage"
          date="19-04-2021"
          image={img3}
          text="We participated and won in the remote scrimmage organised by the Infinity Bolts FTC team. This competition was limited, with (only) 6 interested teams"
        />

        {content}
      </div>
    );
  }
}

export default News;
