import React from 'react';
import './Menu.css';

const Menu = props => {
  const list = ['Team', 'Members', 'Mentors', 'About FIRST'];

  const items = list.map((el, index) => {
    let classes = 'no-underline';
    if (props.underlined === index) classes = 'underlined';

    return (
      <h4 key={el} className={classes} onClick={() => props.clickedItem(index)}>
        {el}
      </h4>
    );
  });
  return <div className="menu">{items}</div>;
};

export default Menu;
