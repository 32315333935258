import React, { Component } from 'react';
import Banner from './components/Banner/Banner';
import Robots from './components/Robots/Robots';
import BriefInfo from './components/BriefInfo/BriefInfo';

class Home extends Component {
  render() {
    return (
      <div>
        <Banner />
        <Robots />
        <BriefInfo />
      </div>
    );
  }
}

export default Home;
