import React from 'react';
import './../../Members/Member/Member.css';

const Mentor = (props) => {
  return (
    <div className="member">
      <img src={props.image} alt="" />

      <div className="details">
        <h4 className="name">{props.name}</h4>
        <div className="detail-wrapper">
          <label className="detail">Role:</label>
          <span className="answer"> &nbsp;{props.role}</span>
        </div>

        <div className="detail-wrapper">
          <label className="detail">Description:</label>
          <p style={{ marginTop: '1em' }}>{props.description}</p>
        </div>
      </div>
    </div>
  );
};

export default Mentor;
