import React, { Component } from 'react';
import './Sponsors.css';
import Sponsor from './Sponsor';

import aptivIMG from './images/aptiv.png';
// import domIMG from './images/dom.png';
import cmca from './images/cmca.jpeg';
import distinct from './images/distinct.jpeg';
import licco from './images/licco.jpeg';
import ccja from './images/ccja.jpeg';
import kimball from './images/kimball.jpeg';

class Sponsors extends Component {
  render() {
    return (
      <div className="sponsors-page">
        <div className="page-title">
          <h2>
            <span>Our Sponsors</span>
          </h2>
        </div>
        <Sponsor
          image={aptivIMG}
          // description="One of our sponsors (the biggest) is Aptiv. Aptiv is a technology company that tries to make vehicles safer and greener and enable the future of mobility. Thanks to their financial support we are able to attend Maryland Tech Invitational."
          name="Aptiv - Our main sponsor"
        />
        <Sponsor
          image={cmca}
          // description="The other sponsor is Dom Caffe. Dom Caffe is a cafeteria that supports us."
          name="Centrul Municipal de Cultură Arad"
        />
        <Sponsor 
        image={distinct}
        name="Distinct Mob"
        />
        <Sponsor 
        image={licco}
        name="Licco Prompt"
        />
        <Sponsor 
        image={kimball}
        name="Kimball Electronics"
        />
        <Sponsor 
        image={ccja}
        name="Centrul Cultural Județean Arad"
        />
      </div>
    );
  }
}

export default Sponsors;
